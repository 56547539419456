<template>
    <div>
        <router-view/>
        <activity-logout-alert-modal v-if="session.logged_in" />
    </div>
</template>

<script>
import ActivityLogoutAlertModal from '@/components/customer/modals/ActivityLogoutAlertModal'
import {mapState, mapMutations, mapGetters, mapActions} from 'vuex';

export default {
    watch: {
        'sites.title'() {
            document.title = this.sites.title
        }
    },
    created() {
        document.title = this.sites.title

        const favicon = document.getElementById("favicon");
        favicon.href = this.favicon

        this.startLastActivityTimer()

        if (this.language_intent) {
            this.updateActiveLanguage(this.language_intent);
        }
    },
    computed: {
        ...mapState(['language_intent', 'session', 'favicon']),
        ...mapGetters(['sites'])
    },
    methods: {
        ...mapMutations(['updateActiveLanguage']),
        ...mapActions(['startLastActivityTimer'])
    },
    components: {
        ActivityLogoutAlertModal
    }
}
</script>
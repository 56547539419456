<template>
    <div>
        <div class="video-overlay" v-if="enabled">
            <div class="video-bg" @click="$emit('disableVideo')"></div>

            <div class="video-player-holder">
                <svg @click="$emit('disableVideo')"><use href="#cross-close"></use></svg>
                <video-player :options="videoOptions" />
            </div>
        </div>
    </div>
</template>

<script>
    import VideoPlayer from '@/components/VideoPlayer'
    
    export default {
        props: {
            enabled: {
                type: Boolean,
                default: false,
            },
            videoOptions: {}
        },
        data() {
            return {
                enable_popup: this.enabled
            }
        },
        components: {
            VideoPlayer
        }
    }
</script>

<style>
    .video-overlay {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 100;
        width: 100%;
        height: 100%;
    }

    .video-overlay .video-bg {
        background-color: #000000;
        opacity: .5;
        width: 100%;
        height: 100%;
    }

    .video-player-holder {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .video-player-holder svg {
        position: absolute;
        right: 0;
        top: -25px;
        width: 15px;
        height: 15px;
        fill: #333333;
        cursor: pointer;
    }

    .video-player-holder svg:hover {
        fill: #FFFFFF;
    }

    .video-js .vjs-big-play-button {
        display: none !important;
    }
</style>

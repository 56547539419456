<script>

    import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';

    import { EA_SWITZERLAND } from "../helpers/brand-helper";
    import eventBus from '@/libs/eventBus'
    import { POLISH } from "../helpers/language-code-helper";

    export default {

        data() {
            return {
                video_popup: false,
                video_width: '1000',
                current_video: {
                    src: '',
                    type: 'video/mp4'
                }
            }
        },

        computed: {

            ...mapState([
                "api",
                "brand",
                "configuration",
                "home",
                "links",
                "menu",
                "session",
                "videos",
            ]),

            ...mapGetters([
                "activeLanguage",
                "availableLanguages",
                "brandIsCardifBnpFr",
                "commonTranslations",
                "dropdownLanguages",
                "getVideos",
                "isSSO",
            ]),

            videoOptions: {
                get() {
                    return {
                        autoplay: true,
                        controls: true,
                        width: this.video_width,
                        sources: [this.current_video]
                    }
                },
                set(value) {
                    this.current_video.src = value
                }
            },
            customerSupportNumber() {
                if (this.commonTranslations['customer-support-number'] !== undefined) {
                    return JSON.parse(this.commonTranslations['customer-support-number'])
                }

                return ''
            },
            faqsUrl () {
                if (this.activeLanguage.code === POLISH && this.brand === EA_SWITZERLAND) {
                    return "https://europ-assistance-demo.myidentityprotectiononline.com/resources/faq";
                }

                return "/customer/faqs";
            }
        },
        methods: {
            ...mapMutations(['updateLanguageIntent', 'setMenu']),
            ...mapActions(['changeLanguage', 'logStatistic']),
            iconClick(link) {
                if (link.length > 0) {
                    window.location.href = link
                }
            },
            async languageChanged(language) {
                if (this.session.logged_in) {
                    await this.changeLanguage(language)
                } else {
                    this.updateLanguageIntent(language.alias)
                }

                location.reload();
            },
            openPopupVideoWithSource(src, name = '') {
                if (window.$('.navmenu').hasClass('canvas-slid') === true) {
                    window.$('.navmenu').offcanvas('hide')
                }

                this.videoOptions = src
                this.video_popup = true

                if (this.session.logged_in) {
                    this.logStatistic(`POPUP-VIDEO-PLAYED-${name.toUpperCase()}`)
                }
            },
            disableVideo() {
                this.video_popup = false
            },
            displayVideos() {
                let videos = {
                    name: this.commonTranslations['videos_title'],
                    icon: 'film-1',
                    link: '',
                    active: false,
                    dropdown: []
                }

                Object.keys(this.getVideos).forEach(key => {
                    if (this.getVideos[key].length > 0) {
                        if (this.session.logged_in) {
                            videos.active = true
                        }

                        videos.dropdown.push({
                            name: this.commonTranslations[`video_text_${key}`],
                            key: `video_text_${key}`,
                            icon: 'video-player',
                            link: this.getVideos[key]
                        })
                    }
                })

                return videos
            }
        },
        created() {
            eventBus.$on('loadPopupVideoSrc', (data) => {
                this.openPopupVideoWithSource(data.src, data.name)
            })

            let menu = {
                dashboard: {
                    name: this.commonTranslations['menu-home'],
                    icon: 'dashboard-1',
                    link: '/customer/dashboard',
                    active: this.session.logged_in,
                    dropdown: []
                },
                my_account: {
                    name: this.commonTranslations['menu-my-account'],
                    icon: 'user-1',
                    link: '',
                    active: this.session.logged_in && ! this.brandIsCardifBnpFr,
                    dropdown: [
                        {
                            name: this.commonTranslations['menu-profile'],
                            icon: 'user-1',
                            link: '/customer/my-account/profile'
                        },
                        {
                            name: this.commonTranslations['menu-change-password'],
                            icon: 'key',
                            link: '/customer/my-account/change-password'
                        },
                        {
                            name: this.commonTranslations['menu-signout'],
                            icon: 'logout',
                            link: '/customer/logout'
                        }
                    ]
                },
                videos: this.displayVideos(),
                about: {
                    name: this.commonTranslations['menu-about'],
                    icon: 'circle-3',
                    link: '',
                    active: this.session.logged_in,
                    dropdown: [
                        {
                            name: this.commonTranslations['menu-faqs'],
                            icon: 'faqs',
                            link: this.faqsUrl,
                        }
                    ],
                },
                login: {
                    name: this.commonTranslations['sign-in'],
                    icon: 'user-1',
                    link: '/auth/login',
                    active: ((this.$route.href.indexOf('general') !== -1) && !this.session.logged_in),
                    dropdown: []
                },
                contact: {
                    name: this.commonTranslations['contact'],
                    icon: this.commonTranslations['contact-icon'],
                    link: '',
                    active: true,
                    dropdown: [
                        {
                            name: this.commonTranslations['customer-support-txt'],
                            key: 'customer-support-txt',
                            icon: '',
                            link: '',
                            active: false,
                            class: 'contact'
                        }
                    ]
                }
            }

            if (typeof this.customerSupportNumber === 'object') {
                Object.values(this.customerSupportNumber).forEach(support_number => {
                    if (support_number.key === 'email') {
                        menu.contact.link = `mailto:${support_number.value}`
                        menu.contact.dropdown = []
                    } else {
                        menu.contact.dropdown.push({
                            name: support_number.value,
                            icon: 'technology-9',
                            link: `tel:${support_number.key}`
                        })
                    }
                })
            }

            if (this.configuration.documents[this.activeLanguage.alias].user_guide.length > 0) {
                menu.about.dropdown.push({
                    name: this.commonTranslations['menu-user-guide'],
                    icon: 'cogs-2',
                    link: `${this.api.assets_url}/${this.configuration.documents[this.activeLanguage.alias].user_guide}`,
                    target: '_blank'
                })
            }

            this.setMenu(menu);
        }
    }
</script>

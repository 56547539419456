<template>
    <div id="general">
        <Header :display_menu="false" />

        <div class="page-not-found">
            <h1>404</h1>
            <h2>{{ commonTranslations['page-not-found'] }}</h2>
        </div>
    </div>
</template>

<script>
    import Header from '@/components/customer/Header'
    import { mapGetters } from 'vuex';

    export default {
        components: {
            Header
        },
        computed: {
            ...mapGetters(['commonTranslations'])
        }
    }
</script>

<style scoped>
    div.page-not-found {
        margin-top: 20px;
    }

    h1, h2 {
        font-family: "Roboto", sans-serif;
        text-align: center;
        font-weight: 300 !important;
    }

    h1 {
        font-size: 100px;
    }
</style>

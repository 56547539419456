<template>
    <div id="logoutInactivityModal" class="modal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ authTranslations['logout-inactivity-warning-header'] }}</h5>
                </div>
                <div class="modal-body">
                    <p>{{ authTranslations['logout-inactivity-warning'] }}</p>
                </div>
                <div class="modal-footer buttons">
                    <button type="button" class="btn btn-danger" @click="closeModal(true)" >
                        {{ commonTranslations['menu-signout'] }}
                    </button>
                    <button type="button" class="btn btn-primary" @click="closeModal(false)" data-dismiss="modal">
                        {{  commonTranslations['continue'] }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .modal {
        overflow-y: hidden !important;
        .btn-primary {
            border: 1px solid #128b56;
            background: #128b56;
            &:hover {
                background: #15965e;
            }
        }
        .modal-footer {
            border-top: none;
        }
        .buttons {
            display: flex;
            justify-content: flex-end;
            gap: 1em;
        }
    }

    #logoutInactivityModal:deep(.modal-dialog) {
        height: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
</style>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    computed: {
        ...mapGetters(['commonTranslations', 'authTranslations']),
    },
    methods: {
        ...mapActions(['refreshActivity']),
        closeModal(logout) {
            window.$('#logoutInactivityModal').modal('hide')
            if (logout) {
                this.$router.replace('/logout')
            } else {
                this.refreshActivity()
            }
        },
    }
}
</script>
<template>
    <div class="form-group">
        <div class="input-group d-block">
            <label class="control-label">{{ label }}</label>
            <input
                class="form-control"
                :class="{'disabled': disabled}"
                :type="type"
                :placeholder="placeholder"
                :value="modelValue"
                :disabled="disabled"
                @input="$emit('update:modelValue', $event.target.value)"
            />
        </div>
        <small v-if="inputInfo.length" class="form-text input-info text-left d-block">{{ inputInfo }}</small>
        <small v-if="error.length" class="form-text text-danger text-left d-block">{{ error }}</small>
    </div>
</template>

<script>
    export default {
        props: {
            label: {
                type: String,
                default: ''
            },
            type: {
                type: String,
                default: 'text'
            },
            placeholder: {
                type: String,
                default: 'Enter value'
            },
            disabled: {
                type: Boolean,
                default: false
            },
            modelValue: {
                type: String,
                default: ''
            },
            inputInfo: {
                type: String,
                default: ''
            },
            error: {
                type: String,
                default: ''
            }
        }
    }
</script>
<style scoped>
    .input-info {
        color: gray;
    }
</style>
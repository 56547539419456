export const validateForm = (fields, callback) => {
    let valid = true;

    fields.forEach(({ field, value, rule, params }) => {
        let error = rule(value, params);

        // update valid value
        valid = valid && !error;

        // update field's error only if it exists
        if (error.length) {
            callback({
                fields: {
                    [field]: {value, error}
                }
            });
        }
    });

    return valid;
}
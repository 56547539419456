export const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export const setCookie = ({name, value, path = '/'}) => {
    document.cookie = `${name}=${value};path=${path}`;
}

export const time = {
    seconds: {
        one: 1000,
        five: 5000,
        ten: 10000,
        thirty: 30000,
    },
    minutes: {
        one: 60000,
        two: 120000,
        five: 300000,
        eight: 480000,
        nine: 540000,
        ten: 600000
    }
}
import {createStore} from 'vuex'
import VuexPersistence from 'vuex-persist'
import axios from '../libs/axios';
import RequestException from '../exceptions/RequestException';
import {time} from '@/libs/helpers';
import { CARDIF_FR } from '../helpers/brand-helper';

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    reducer: (state) => ({
        brand: state.brand,
        language: state.language,
        language_intent: state.language_intent,
        languages: state.languages,
        api: state.api,
        logo: state.logo,
        home: state.home,
        general: state.general,
        terms_of_privacy: state.terms_of_privacy,
        translations: state.translations,
        faqs: state.faqs,
        configuration: state.configuration,
        scores: state.scores,
        breadcrumbs: state.breadcrumbs,
        menu: state.menu,
        links: state.links,
        auth: state.auth,
        page_title: state.page_title,
        svg: state.svg,
        footer: state.footer,
        mobile: state.mobile,
        customer: {
            loading: state.customer.loading,
            logged_in: state.customer.logged_in,
            dashboard: {
                online_resources: state.customer.dashboard.online_resources,
                identity_assistance: state.customer.dashboard.identity_assistance
            }
        }
    }),
})

const vuexSession = new VuexPersistence({
    storage: window.sessionStorage,
    reducer: (state) => ({
        session: state.session,
        activity: state.activity,
        sso: state.sso
    })
})

export default createStore({
    state: {
        brand: process.env['VUE_APP_BRAND'],
        language: process.env['VUE_APP_BASE_LANGUAGE'],
        languages: [],
        language_sets: ['common','auth','landing','dashboard','profile','change-password','guest-password','reset-password'],
        language_intent: null,
        is_sso: (process.env['VUE_APP_SSO'] === 'true') ? true : false,
        guest_login_access_key: process.env['VUE_APP_GUEST_LOGIN'],
        api: {
            url: process.env['VUE_APP_API_URL'],
            assets_url: process.env['VUE_APP_CDN'],
            home_ep: process.env['VUE_APP_API_HOME_END_POINT'],
            auth_ep: process.env['VUE_APP_API_AUTH_END_POINT'],
            login_ep: process.env['VUE_APP_API_LOGIN_END_POINT'],
            register_ep: process.env['VUE_APP_API_REGISTRATION_END_POINT'],
            activation_ep: process.env['VUE_APP_API_ACTIVATION_END_POINT'],
            dashboard_assets: process.env['VUE_APP_API_DASHBOARD_ASSETS_END_POINT'],
            cdn: process.env['VUE_APP_API_CDN']
        },
        sso: {
            data: {}
        },
        favicon: `${process.env['VUE_APP_CDN']}/cardif/assets/images/favicon/${process.env['VUE_APP_BRAND']}/favicon.ico`,
        logo: {
            src: '/assets/img/logos/bnp-paribas-cardif-logo.png'
        },
        footer: {},
        general: {
            logo_height: 75,
            cdn: ''
        },
        terms_of_privacy: '',
        videos: {
            introduction: ''
        },
        isMobile: false,
        mobile: {
            token: '',
            iv: ''
        },
        translations: {},
        faqs: {},
        configuration: {},
        scores: {
            assets: {assetCount: 0, assetPercent: 0},
            overall: {overall: 0, risk: 'low'}
        },
        breadcrumbs: [],
        menu: {},
        links: {
            login: '/auth/login',
            register: '/auth/register',
            forgotPassword: '/auth/forgot-password',
            guestPassword: '/auth/guest-password'
        },
        svg: {
            location: 'carrefour/Svg'
        },
        page_title: 'Cyber Life Protection',
        home: {
            loading: true,
            logo_height: 85,
            features: {
                button: 'Learn More',
                blocks: []
            },
        },
        auth: {
            message: {
                active: false,
                message: ''
            },
            loading: true,
            login: {
                title: 'Sign In',
                username: 'Username',
                password: 'Password',
                button: 'Log In',
            },
            health_checker_items: {
                list: [],
                accepted: false
            }
        },
        customer: {
            details: {},
            loading: true,
            logged_in: false,
            profile: {
                form: {
                    first_name: {
                        value: '',
                        error: ''
                    },
                    last_name: {
                        value: '',
                        error: ''
                    },
                    email: {
                        value: '',
                        error: ''
                    },
                    alias: {
                        value: '',
                        error: ''
                    },
                    phone: {
                        value: '',
                        error: ''
                    },
                    dob: {
                        value: '',
                        error: ''
                    }
                }
            },
            identity_assistance_request: {
                form: {
                    subject: {

                    },
                    first_name: {
                        value: '',
                        error: ''
                    },
                    last_name: {
                        value: '',
                        error: ''
                    },
                    email: {
                        value: '',
                        error: ''
                    },
                    phone: {
                        value: '',
                        error: ''
                    },
                    preferred_time: {
                        value: ''
                    },
                    reference: {
                        value: ''
                    },
                    comment: {
                        value: ''
                    }
                }
            },
            dashboard: {
                block_menu: {},
                identity_risk_page: {
                    hit_results: [],
                    policies: {}
                },
                data_monitoring_page: {
                    mobile: {
                        assets: {}
                    },
                    data_entries: {}
                },
                download_page_computer: {
                    installs: {
                        installed: 0,
                        available: 5
                    }
                },
                download_page_mobile: {
                    installs: {
                        installed: 0,
                        available: 5
                    }
                },
                identity_assistance: {
                    title: "24/7 Identity Assistance",
                    request: {
                        dropdown: [
                            'file-a-claim',
                            'request-an-update-on-an-open-case',
                            'ask-a-billing-question',
                            'get-help-using-the-dashboard',
                            'inquire-about-something-else'
                        ]
                    },
                    content: {}
                },
                online_resources: {
                    menu: {},
                    posts: {}
                }
            }
        },
        session: {
            logged_in: false
        },
        activity: {
            time: null,
            timer: null,
            alert: {
                displayed: false
            },
        }
    },
    getters: {
        activityAlertDisplayed(state) {
            return state.activity.alert.displayed
        },
        getLastActivity(state) {
            return state.activity.time
        },
        getLogo(state) {
            return state.configuration.logo
        },
        getVideos(state, getters) {
            return state.configuration.videos[getters.activeLanguage.alias]
        },
        getCSS(state) {
            return state.configuration.style.css
        },
        getBaseColour(state) {
            return state.configuration.style.base_colour
        },
        getLevelColors(state) {
            return state.configuration.level_colors
        },
        isSSO(state) {
            return state.is_sso
        },
        getSSOSessionData(state) {
            return state.sso.data
        },
        enabledProducts(state) {
            if (!Object.prototype.hasOwnProperty.call(state.configuration, 'products')) {
                return null;
            }

            const enabled = []
            const { products } = state.configuration

            for (const p in products) {
                if (parseInt(products[p]) === 1) {
                    enabled.push(p)
                }
            }

            return enabled
        },
        getAuthMessage(state) {
            return state.auth.message
        },
        productMenuItems(state) {
            return state.customer.dashboard.block_menu
        },
        isProductEnabled: (state) => (product = null) => {
            const { products } = state.configuration
            return products[product] && parseInt(products[product]) === 1
        },
        getMobileProductDownloads(state) {
            const { products } = state.configuration
            return products.download.mobile
        },
        isMobile(state) {
            return state.isMobile
        },
        getMobileAssets(state) {
            return state.customer.dashboard.data_monitoring_page.mobile.assets
        },
        availableLanguages(state) {
            return state.languages
        },
        activeLanguage(state) {
            if (state.languages.length === 1) {
                return state.languages.find(element => element.alias)
            }

            return state.languages.find(element => element.alias === state.language);
        },
        dropdownLanguages(state) {
            return state.languages.filter(element => element.alias !== state.language);
        },
        languageTranslations(state) {
            return state.translations[state.language]
        },
        commonTranslations(state, getters) {
            return getters.languageTranslations.common
        },
        landingTranslations(state, getters) {
            return getters.languageTranslations.landing
        },
        authTranslations(state, getters) {
            return getters.languageTranslations.auth
        },
        dashboardTranslations(state, getters) {
            return getters.languageTranslations.dashboard
        },
        profileTranslations(state, getters) {
            return getters.languageTranslations.profile
        },
        passwordTranslations(state, getters) {
            return getters.languageTranslations['change-password']
        },
        getCustomer(state) {
            return state.customer.details
        },
        getInstallationGuides(state, getters) {
            return state.configuration.documents[getters.activeLanguage.alias].installation_guide
        },
        sites(state) {
            return {
                logo: state.logo.src,
                title: state.page_title
            }
        },
        getFooter(state, getters) {
            return state.footer[getters.activeLanguage.alias]
        },
        identityAssistanceRequestDropdown(state) {
            return state.customer.dashboard.identity_assistance.request.dropdown
        },
        onlineSupportAssistance(state, getters) {
            return state.customer.dashboard.identity_assistance.content[getters.activeLanguage.alias]
        },
        onlineResourcesMenu(state) {
            return state.customer.dashboard.online_resources.menu
        },
        onlineResourcesPosts(state, getters) {
            return state.customer.dashboard.online_resources.posts[getters.activeLanguage.alias]
        },
        getOnlineResourcesPostById: (state, getters) => (id) => {
            return state.customer.dashboard.online_resources.posts[getters.activeLanguage.alias].find((post) => {
                if (post.id === parseInt(id)) {
                    return post
                }
            })
        },
        brandIsCardifBnpFr (state) {
            return state.brand === CARDIF_FR;
        },
        isInCardifFrWebView (state, getters) {
            const isInCardifFrAppWebView = sessionStorage.getItem("webview") === "true";
            const brandIsCardifFr        = getters.brandIsCardifBnpFr;

            return brandIsCardifFr && isInCardifFrAppWebView;
        }
    },
    mutations: {
        setLastActivityAlertDisplay(state, display) {
            state.activity.alert.displayed = display
        },
        startLastActivityTimer(state) {
            if (state.session.logged_in) {
                if (state.activity.timer) {
                    clearInterval(state.activity.timer);
                }

                state.activity.timer = setInterval(() => {
                    const now = new Date();
                    const then = new Date(state.activity.time);
                    const elapsed = Math.abs(now - then);

                    if (elapsed > time.minutes.nine && elapsed < time.minutes.ten && !state.activity.alert.displayed) {
                        const modal = window.$('#logoutInactivityModal')
                        if (modal) {
                            modal.modal({
                                backdrop: 'static',
                                keyboard: false
                            });

                            modal.modal('show');
                        }
                        state.activity.alert.displayed = true
                    } if (elapsed > time.minutes.ten) {
                        clearInterval(state.activity.timer);
                        window.location.href = '/customer/logout';
                    }
                }, 1000)
            }
        },
        stopLastActivityTimer(state) {
            clearInterval(state.activity.timer)
        },
        updateLastActivity(state, time) {
            state.activity.time = time
        },
        termsOfPrivacyUpdate(state, data) {
            state.terms_of_privacy = data
        },
        setIsMobile(state, isMobile) {
            state.isMobile = isMobile;
        },
        updateLanguageIntent(state, payload) {
            state.language_intent = payload;
        },
        updateActiveLanguage(state, payload) {
            state.language = payload;
            state.language_intent = null;
        },
        sessionLoggedIn(state, logged_in) {
            state.session.logged_in = logged_in
        },
        updateMobileCredentials(state, payload) {
            state.mobile = {...state.mobile.token, ...payload};
        },
        toggleArticles(state, tag) {
            let toggle = true;

            if (state.customer.dashboard.online_resources.menu.tags[tag].active) {
                toggle = false
            }

            state.customer.dashboard.online_resources.menu.tags[tag].active = toggle;

            state.customer.dashboard.online_resources.articles.forEach((value) => {
                if (value.tag === tag) {
                    value.display = toggle;
                }
            })
        },
        setTranslationsSkeleton(state) {
            state.languages.forEach(({ alias }) => {
                if (!Object.prototype.hasOwnProperty.call(state.translations, alias)) {
                    state.translations = {
                        ...state.translations,
                        [alias]: {}
                    }
                }

                state.language_sets.forEach((set) => {
                    if (!Object.prototype.hasOwnProperty.call(state.translations[alias], set)) {
                        state.translations[alias] = {
                            ...state.translations[alias],
                            [set]: {}
                        }
                    }
                })
            })
        },
        addTranslations(state, data) {
            data.language.forEach(({language, set, key, text}) => {
                // does language already exist?
                if (!Object.prototype.hasOwnProperty.call(state.translations, language)) {
                    state.translations = {
                        ...state.translations,
                        [language]: {}
                    }
                }

                // does set already exist?
                if (!Object.prototype.hasOwnProperty.call(state.translations[language], set)) {
                    state.translations[language] = {
                        ...state.translations[language],
                        [set]: {}
                    }
                }

                // the structure is present so we just need to apply the value
                state.translations[language][set][key] = text;
            });
        },
        addFAQS(state, data) {
            state.faqs = {
                ...state.faqs,
                [state.language]: {
                    loaded: false,
                    content: []
                }
            }

            data.forEach(item => {
                if (!Object.prototype.hasOwnProperty.call(state.faqs, item.language)) {
                    state.faqs[item.language] = {
                        loaded: false,
                        content: []
                    }
                }

                // update content
                state.faqs[item.language].content.push(item);
            });
        },
        setupBrandIdentity(state, data) {
            const { availableLanguages, configurations } = data.site;

            state.logo.src = data.site.logo
            state.page_title = data.site.title
            state.api.cdn = data.cdn

            //Set Languages
            state.languages = []

            availableLanguages.forEach(language => {
                state.languages.push({
                    alias: language.name,
                    name: language.label,
                    code: language.code,
                    icon: language.icon,
                    locale: language.locale
                })

                state.customer.dashboard.identity_assistance.content[language.name] = []
                state.customer.dashboard.online_resources.posts[language.name] = []
            })

            if (availableLanguages.length === 1 && Object.prototype.hasOwnProperty.call(availableLanguages[0], 'name')) {
                state.language = availableLanguages[0].name;
            }

            state.footer = configurations.footer
        },
        loadBrandConfiguration(state, {key, configuration, loading}) {
            state.configuration = configuration
            state[key].loading = loading
        },
        setPageLoading(state, {key, value}) {
            state[key].loading = value 
        },
        setSSOData(state, {key, value}) {
            state.sso.data[key] = value
        },
        updateFeatureBlocks(state, data) {
            state.home.features.blocks = []
            for (const feature of data) {
                if (feature.enabled) {
                    state.home.features.blocks.push(feature)
                }
            }
        },
        updateHealthChecker(state, data) {
            state.auth.health_checker_items.list = data.list
            state.auth.health_checker_items.accepted = data.accepted
        },
        updateDataMonitoringEntries(state, data) {
            state.customer.dashboard.data_monitoring_page.data_entries = data
        },
        setAuthMessage(state, data) {
            state.auth.message.active = data.active
            state.auth.message.message = data.message
        },
        authMessage(state, data) {
            state.auth.message = data
        },
        authenticateCustomer(state) {
            state.session.logged_in = true
        },
        setCustomerDetails(state, data) {
            state.customer.details = data
        },
        logoutCustomer(state) {
            state.session.logged_in = false
            state.auth.message = {
                active: true,
                message: 'You have logged out successfully'
            }
        },
        updateLoadingStatus(state, page) {
            state[page].loading = false
        },
        setUpDashboard(state, data) {
            state.customer.loading = false

            if (data.overallScore !== undefined) {
                state.scores = {
                    assets: data.assetScores,
                    overall: data.overallScore
                }
            }
        },
        setDashboardScores(state, data) {
            if (data.overallScore !== undefined) {
                state.scores.overall = {
                    overall: data.overallScore.overall,
                    risk: data.overallScore.risk
                }
            }

            if (data.assetScores !== undefined) {
                state.scores.assets = {
                    assetCount: data.assetScores.assetCount,
                    assetPercent: data.assetScores.assetPercent
                }
            }
        },
        setUpDashboardBlockMenu(state, data) {
            state.customer.dashboard.block_menu = data.menu
        },
        setDashboardBlockMenuLoadingState(state, data) {
            Object.values(state.customer.dashboard.block_menu).forEach(value => {
                value.loading = false
            })

            state.customer.dashboard.block_menu[data.path].loading = data.loading
        },
        setUpDashboardPageContent(state, data) {
            Object.keys(data).forEach(key => {
                state.customer.dashboard[key] = data[key]
            })
        },
        setODPInstalls(state, {key, type, value}) {
            state.customer.dashboard[key].installs[type] = parseInt(value)
        },
        identityRiskPageContent(state, data) {
            state.customer.dashboard.identity_risk_page = {
                hits: data.hits,
                policies: data.policies,
                scores: data.scores
            }
        },
        mobileDataMonitoringSetAssets(state, data) {
            state.customer.dashboard.data_monitoring_page.mobile.assets = data
        },
        dataMonitoringCreateAssets(state, data) {
            state.customer.dashboard.data_monitoring_page.data_entries = data
        },
        updateDataMonitoringItem(state, data) {
            let assets = data.asset,
                entries = state.customer.dashboard.data_monitoring_page.data_entries[data.name]

            if (data.type === 'add') {
                if (data.name === 'personal-details') {
                    for (let i in assets[0]) {
                        entries.data[0][i] = assets[0][i]
                    }
                } else {
                    entries.data = []
                    entries.data = assets
                }

                entries.count += 1
            }

            if (data.type === 'update') {
                if (data.name === 'personal-details') {
                    entries.data[0].dob = assets.dob
                    entries.data[0].sd_id = assets.sd_id
                    entries.data[0].p_id = assets.pid
                } else {
                    for (let key in entries.data[data.index]) {
                        entries.data[data.index][key] = assets[key]
                    }
                }
            }

            if (data.type === 'delete') {
                if (data.name === 'personal-details') {
                    entries.data[0].dob = ''
                    entries.data[0].sd_id = ''
                    entries.data[0].p_id = ''
                } else {
                    for (let i in entries.data) {
                        if (entries.data[i].sd_id === assets.sd_id) {
                            entries.data.splice(i, 1)
                        }
                    }
                }

                entries.count -= 1
            }

            let count = 0;

            for (let i in state.customer.dashboard.data_monitoring_page.data_entries) {
                count += state.customer.dashboard.data_monitoring_page.data_entries[i].count
            }

            state.scores.assets.assetCount = count
        },
        setBreadCrumbs(state, data) {
            state.breadcrumbs = data
        },
        setMenu(state, data) {
            state.menu = data
        },
        updateCustomerDetails(state, payload) {
            state.customer.details = payload;
        },
        resetCustomerProfileForm(state, {first_name, last_name, login_id, alias, phone, dob}) {
            state.customer.profile.form.first_name.value = first_name;
            state.customer.profile.form.last_name.value = last_name;
            state.customer.profile.form.email.value = login_id;
            state.customer.profile.form.alias.value = alias;
            state.customer.profile.form.phone.value = phone;
            state.customer.profile.form.dob.value = dob;
        },
        updateFormField(state, {type, section, fields}) {
            state[type][section].form = {...state[type][section].form, ...fields};
        },
        toggleLoading(state, {type, value}) {
            state[type].loading = value;
        },
        setOnlineSupportAssistance(state, {response, getters}) {
            let assistance = response.data.data

            if ('onlineSupport' in response.data) {
                assistance = response.data.onlineSupport
            }

            state.customer.dashboard.identity_assistance.content[getters.activeLanguage.alias] = assistance
        },
        setOnlineSupportAssistanceRequestFormReset(state) {
            Object.keys(state.customer.identity_assistance_request.form).forEach(key => {
                if (key !== 'subject') {
                    state.customer.identity_assistance_request.form[key].value = ''
                }
            })
        },
        setOnlineResourcesType(state, menu) {
            state.customer.dashboard.online_resources.menu = {};

            menu.forEach((value)=>{
                state.customer.dashboard.online_resources.menu[value.type] = value
            })
        },
        setOnlineResourcesPosts(state, {response, getters}) {
            state.customer.dashboard.online_resources.posts[getters.activeLanguage.alias] = []

            response.data.data.posts.forEach((value)=> {
                state.customer.dashboard.online_resources.posts[getters.activeLanguage.alias].push(value)
            })
        }
    },
    actions: {
        async refreshActivity() {
            try {
                await axios.get('refresh')
            } catch (error) {
                throw new Error(error)
            }
        },
        setLastActivityAlertDisplay({ commit }, display) {
            commit('setLastActivityAlertDisplay', display)
        },
        startLastActivityTimer({ commit }) {
            commit('startLastActivityTimer');
        },
        stopLastActivityTimer({ commit }) {
            commit('stopLastActivityTimer');
        },
        updateLastActivity({ commit }, time) {
            commit('updateLastActivity', time);
        },
        async changeLanguage({commit}, language) {
            try {
                const response = await axios.put('customer/locale', {
                    locale: language.locale
                })

                commit('updateLanguageIntent', language.alias)

                return response
            } catch (error) {
                throw new Error(error)
            }
        },
        sessionLoggedIn({commit}, logged_in) {
            commit('sessionLoggedIn', logged_in)
        },
        toggleArticles({commit}, tag) {
            commit('toggleArticles', tag)
        },
        homePageApiRequest({commit, state}) {
            axios.post(
                state.api.home_ep).then(
                    response => {
                        commit('setupBrandIdentity', response.data)
                        commit('setTranslationsSkeleton')
                        commit('addTranslations', response.data)

                        commit('loadBrandConfiguration', {
                            key: 'home',
                            configuration: response.data.site.configurations,
                            loading: false
                        })
                    }
                )
        },
        async authPageApiRequest({commit, state}) {
            try {
                const response = await axios('/assets/auth', {
                    params: {
                        language: state.language
                    }
                })

                commit('setTranslationsSkeleton')
                commit('addTranslations', response.data)
                commit('setupBrandIdentity', response.data)
                commit('loadBrandConfiguration', {
                    key: 'auth',
                    configuration: response.data.site.configurations,
                    loading: state.is_sso ? true : false
                })

                return response
            } catch (error) {
                console.log('Error - ', error)
            }
        },
        async getSSOLoginBranch({commit}, branch) {
            commit
            branch
            try {
                let type_branch = (branch !== 'auth0') ? `/${branch}` : '';

                const response = await axios(`/sso/register${type_branch}`)

                commit('setSSOData', {
                    key: 'auth_url',
                    value: response.data.authoriseUrl
                })

                commit('setSSOData', {
                    key: 'state',
                    value: response.data.state
                })

                return response
            } catch (error) {
                throw new RequestException(error.response.data.msg, error.response.status)
            }
        },
        async getSSOAuthorisation({commit}, payload) {
            commit
            try {
                const response = await axios.post(`/sso/register`, payload)

                return response
            } catch (error) {
                return error.response
            }
        },
        async getCustomerDetails({commit}) {
            try {
                const response = await axios('/customer')

                commit('setCustomerDetails', response.data.customer)

                return response
            } catch (error) {
                throw new RequestException(error.response.data.msg, error.response.status)
            }
        },
        updateHealthChecker({commit}, data) {
            commit('updateHealthChecker', data)
        },
        updateDataMonitoringEntries({commit}, data) {
            commit('updateDataMonitoringEntries', data)
        },
        addUpdateDataMonitoringItem({commit}, data) {
            commit('updateDataMonitoringItem', data)
        },
        deleteMonitoringItem({commit}, data) {
            commit('updateDataMonitoringItem', data)
        },
        authMessage({commit}, data) {
            commit('authMessage', data)
        },
        authenticateCustomer({commit}) {
            commit('authenticateCustomer')
        },
        logoutCustomer({commit}) {
            commit('logoutCustomer')
        },
        async getAssetsDashboard({commit, state}) {
            try {
                const response = await axios.get('/assets/dashboard', {
                    params: {
                        language: state.languages.map(element => element.alias)
                    }
                })

                commit('setTranslationsSkeleton')
                commit('addTranslations', response.data);
                commit('setupBrandIdentity', response.data)
                commit('loadBrandConfiguration', {
                    key: 'customer',
                    configuration: response.data.site.configurations,
                    loading: false
                })

                return response
            } catch (error) {
                throw new RequestException(error.response.data.msg, error.response.status)
            }
        },
        async getDashboardScores({commit}) {
            try {
                const response = await axios('/dashboard/scores')
                commit('setDashboardScores', response.data)
                return response
            } catch (error) {
                throw new RequestException(error.response.data.msg, error.response.status)
            }
        },
        setUpDashboardPageContent({commit}, data) {
            commit('setUpDashboardPageContent', data)
        },
        async getProfileAssets({commit, state}) {
            try {
                const response = await axios.get('/assets/profile', {
                    params: {
                        language: state.languages.map(element => element.alias)
                    }
                })

                commit('setTranslationsSkeleton')
                commit('addTranslations', response.data);
            } catch ({response}) {
                throw new RequestException(response.data.msg, response.status);
            }
        },
        async logStatistic({commit}, data) {
            commit
            try {
                const response = await axios.put('/statistic/log', { log: data })

                return response
            } catch ({response}) {
                throw new RequestException(response.data.msg, response.status)
            }
        },
        async getPasswordAssets({commit, state}) {
            try {
                const response = await axios.get('/assets/change-password', {
                    params: {
                        language: state.languages.map(element => element.alias)
                    }
                })

                commit('setTranslationsSkeleton')
                commit('addTranslations', response.data);
            } catch ({response}) {
                throw new RequestException(response.data.msg, response.status);
            }
        },
        async identityRiskPageContent({commit}) {
            try {
                const response = await axios('/dashboard/identity-risk')

                commit('identityRiskPageContent', response.data)

                return response
            } catch (error) {
                throw new RequestException(error.response.data.msg, error.response.status)
            }
        },
        async dataMonitoringContent() {
            try {
                return await axios('/dashboard/data-monitoring')
            } catch (error) {
                throw new RequestException(error.response.data.msg, error.response.status)
            }
        },
        async dataMobileMonitoringContent() {
            try {
                return await axios('mobile/dashboard/data-monitoring')
            } catch (error) {
                throw new RequestException(error.response.data.msg, error.response.status)
            }
        },
        dataMonitoringCreateAssets({commit}, data) {
            commit('dataMonitoringCreateAssets', data)
        },
        async getDownloadsForDevices({commit}, device) {
            console.debug({ commit })
            return await axios(`/dashboard/odp/${device}`)
        },
        async getFAQS({commit}) {
            try {
                const response = await axios.get('/dashboard/faqs');
                commit('addFAQS', response.data.faqs);
            } catch ({response}) {
                throw new RequestException(response.data.msg, response.status);
            }
        },
        setMenu({commit}, data) {
            commit('setMenu', data)
        },
        async forgotPassword({commit}, payload) {
            commit
            try {
                await axios.post('/auth/password/forgot', payload);
            } catch ({response}) {
                throw new RequestException(response.data.message, response.status);
            }
        },
        async forgotPasswordResetOrUnlock({commit}, payload) {
            let uri = '/auth/password/forgot/reset'
            if (Object.prototype.hasOwnProperty.call(payload, 'unlock')) {
                uri = '/auth/account/blocked/reset'
            }

            try {
                await axios.post(uri, payload);
            } catch ({response}) {
                throw new RequestException(response.data.message, response.status);
            }
            commit
        },
        async getCustomer({commit}) {
            commit('toggleLoading', {type: 'customer', value: true});
            try {
                const {data} = await axios.get('/customer');
                commit('updateCustomerDetails', data.customer);
                return data;
            } catch ({response}) {
                throw new RequestException(response.data.msg, response.status);
            } finally {
                commit('toggleLoading', {type: 'customer', value: false});
            }
        },
        async saveCustomer({commit}, payload) {
            commit('toggleLoading', {type: 'customer', value: true});
            try {
                return await axios.put('customer', payload);
            } catch ({response}) {
                throw new RequestException(response.data.message, response.status);
            } finally {
                commit('toggleLoading', {type: 'customer', value: false});
            }
        },
        async changePassword({commit}, payload) {
            commit('toggleLoading', {type: 'customer', value: true});
            try {
                await axios.put('customer/password', payload);
            } catch ({response}) {
                throw new RequestException(response.data.message, response.status);
            } finally {
                commit('toggleLoading', {type: 'customer', value: false});
            }
        },
        async getMobileUserInfo({commit, getters}, payload) {
            try {
                const response = await axios.get('mobile/dashboard', {
                    params: payload
                });
                commit('setupBrandIdentity', response.data)
                commit('updateMobileCredentials', payload)
                commit('updateActiveLanguage', response.data.activeLanguage)
                commit('setTranslationsSkeleton')
                commit('addTranslations', response.data)
                commit('loadBrandConfiguration', {
                    key: 'home',
                    configuration: response.data.site.configurations,
                    loading: false
                })
                commit('setDashboardScores', response.data);
                commit('identityRiskPageContent', response.data);
                commit('setCustomerDetails', response.data.customer);
                commit('addFAQS', response.data.faqs);
                commit('setOnlineSupportAssistance', {response, getters})
                commit('setIsMobile', true);

                return response
            } catch (err) {
                throw new Error(err);
            }
        },
        async loadOnlineResourcesPage({commit}) {
            try {
                const response = await axios.get('dashboard/online-resources/types')
                commit('setOnlineResourcesType', response.data.data)
            } catch (err) {
                throw new Error(err)
            }
        },
        async getOnlineSupportAssistance({commit, getters}) {
            try {
                const response = await axios.get('dashboard/online-support', {
                    params: {
                        language: getters.activeLanguage.alias
                    }
                })

                commit('setOnlineSupportAssistance', {response, getters})
            } catch (err) {
                throw new Error(err)
            }
        },
        async sendSupportRequestForm({commit}, payload) {
            try {
                const response = await axios.post(`dashboard/online-assistance`, payload)

                commit('setOnlineSupportAssistanceRequestFormReset')

                return response
            } catch (err) {
                throw new Error(err)
            }
        },
        async getOnlineResourcesPosts({commit, getters}, type) {
            try {
                const { menu } = this.state.customer.dashboard.online_resources
                const id = menu[type]
                    ? menu[type].id
                    : menu[window.location.href.split("/").pop()].id
                const response = await axios.get(`dashboard/online-resources/types/${id}`, {
                    params: {
                        language: getters.activeLanguage.alias
                    }
                })
                commit('setOnlineResourcesPosts', {response, getters})
            } catch (err) {
                throw new Error(err)
            }
        },
        async searchOnlineResourcesPosts({commit, getters}, search) {
            try {
                const posts = this.state.customer.dashboard.online_resources.posts[getters.activeLanguage.alias]
                const response = {
                    data: {
                        data: {
                            posts: []
                        }
                    }
                }

                const match = (attribute, search) => attribute.toLowerCase().includes(search.toLowerCase())
                const exists = (post, stored) => stored.find((p) => p.id === post.id)

                posts.find((post) => {
                    if (match(post.title, search)) {
                        response.data.data.posts.push(post)
                    }

                    if (match(post.content, search) && !exists(post, response.data.data.posts)) {
                        response.data.data.posts.push(post)
                    }
                })

                commit('setOnlineResourcesPosts', {response, getters})
            } catch (error) {
                throw new Error(error)
            }
        },
    },
    modules: {},
    plugins: [vuexLocal.plugin, vuexSession.plugin]
})

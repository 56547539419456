<template>
    <div class="loadingio-spinner-eclipse-vs65m4nocno" :style="`width:${width}; height:${width};`">
        <div v-show="title !== undefined" class="authorising">{{ title }}...</div>
        <div class="ldio-jomufl0dpl8">
            <div :style="`
                width:${inner_width}px;
                height:${inner_width}px;
                transform-origin: ${transform_origin};`">
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        props: ['width', 'title'],
        data() {
            return {
                inner_width: '160px',
                transform_origin: '80px 82px',
                spinner_width: `${this.width}px`
            }
        },
        computed: {
            ...mapGetters(['isSSO','commonTranslations'])
        },
        created() {
            this.inner_width = this.width - 20

            let transform_o_x = `${this.inner_width / 2}px`
            let transform_o_y = `${(this.inner_width / 2) + 2}px`

            this.transform_origin = `${transform_o_x} ${transform_o_y}`
        }
    }
</script>

<style scoped lang="scss">
    .authorising {
        position: absolute;
        left: 50%;
        top: -50px;
        transform: translate(-50%);
        color: $base-color !important;
        font-size: 20px;
        animation: fadeInOut 1s infinite;
    }

    .loadingio-spinner-eclipse-vs65m4nocno {
        overflow: visible !important;
    }

    @keyframes ldio-jomufl0dpl8 {
        0% { transform: rotate(0deg) }
        50% { transform: rotate(180deg) }
        100% { transform: rotate(360deg) }
    }
    .ldio-jomufl0dpl8 div {
        position: absolute;
        animation: ldio-jomufl0dpl8 1s linear infinite;
        width: 160px;
        height: 160px;
        top: 10px;
        left: 10px;
        border-radius: 50% !important;
        transform-origin: 80px 82px;
    }
    .loadingio-spinner-eclipse-vs65m4nocno {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: v-bind(spinner_width);
        height: v-bind(spinner_width);
        display: inline-block;
        overflow: hidden;
        background: #ffffff;
    }
    .ldio-jomufl0dpl8 {
        position: relative;
        transform: translateZ(0) scale(1);
        backface-visibility: hidden;
        transform-origin: 0 0; /* see note above */
    }
    .ldio-jomufl0dpl8 div { box-sizing: content-box; }

    @keyframes fadeInOut {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
</style>

<template>
    <div>
        <div id="leftMenu" class="navmenu navmenu-default navmenu-fixed-left offcanvas" role="navigation">
            <a class="navmenu-brand" href="#">Menu</a>
            <ul class="nav navmenu-nav">
                <li v-show="item.active" class="active" :key="index" v-for="(item, index) in $store.state.menu">
                    <a :href="item.link"><svg class="flag-icon"><use :href="`#${item.icon}`"></use></svg>{{ item.name }}</a>
                    <ul v-if="item.dropdown.length > 0">
                        <li v-show="dropdown.link.length > 0 && index !== 'videos'" :key="key" v-for="(dropdown, key) in item.dropdown">
                            <a :href="dropdown.link" :target="dropdown.target">
                                <svg class="flag-icon"><use :href="`#${dropdown.icon}`"></use></svg>
                                {{ dropdown.name }}
                            </a>
                        </li>
                        <li v-show="dropdown.link.length > 0 && index === 'videos'" :key="key" v-for="(dropdown, key) in item.dropdown">
                            <a @click="$emit('playPopupVideo', dropdown.link, dropdown.name)">
                                <svg class="flag-icon"><use :href="`#${dropdown.icon}`"></use></svg>
                                {{ dropdown.name }}
                            </a>
                        </li>
                    </ul>
                </li>

                <li class="active" v-if="availableLanguages.length > 1">
                    <a><svg class="flag-icon"><use :href="`#${activeLanguage.icon}`"></use></svg>{{ activeLanguage.name }}</a>
                    <ul>
                        <li v-bind:key="key" v-for="(language, key) in dropdownLanguages">
                            <a class="nav-link" @click="languageChanged(language.alias)">
                                <svg class="flag-icon"><use :href="`#${language.icon}`"></use></svg>
                                    {{ language.name }}
                                </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex';

    export default {
        methods: {
            ...mapMutations(['updateLanguageIntent']),
             languageChanged(language) {
                this.updateLanguageIntent(language);
                location.reload();
            }
        },
        computed: {
            ...mapGetters(['commonTranslations', 'availableLanguages', 'activeLanguage', 'dropdownLanguages'])
        }
    }
</script>

<style lang="scss" scoped>
    .navmenu, .navbar-offcanvas {
        height: auto !important;    
    }

    .navmenu-fixed-left {
        position: fixed;
        height: auto;
        width: 300px;
        overflow-y: auto;
        top: 0;
        bottom: 0;
        border-width: 0 1px 0 0;
    }

    ul.navmenu-nav li ul,
    ul.navmenu-nav li ul li {
        list-style-type: none;
        margin: 0 !important;
    }

    ul.navmenu-nav li ul li,
    ul.navmenu-nav > li > ul > li > a {
        font-weight: 300;
        font-size: 15px;
        color: #777;
        padding: 10px 5px !important;
    }
</style>

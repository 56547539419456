<template>
    <div class="lds-facebook">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</template>

<style scoped lang="css">
    .lds-facebook {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }
    .lds-facebook div {
        display: inline-block;
        position: absolute;
        left: 5px;
        width: 5px;
        background: #26a866;
        animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    }
    .lds-facebook div:nth-child(1) {
        left: 5px;
        animation-delay: -0.36s;
    }
    .lds-facebook div:nth-child(2) {
        left: 15px;
        animation-delay: -0.24s;
    }
    .lds-facebook div:nth-child(3) {
        left: 25px;
        animation-delay: -0.12s;
    }
    .lds-facebook div:nth-child(4) {
        left: 35px;
        animation-delay: 0;
    }
    @keyframes lds-facebook {
        0% {
            top: 0px;
            height: 35px;
        }
        50%, 100% {
            top: 11px;
            height: 8px;
        }
    }
</style>
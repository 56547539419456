import store from '@/store'

export function dpsGuard({ next, router }) {
    if (store.getters.isProductEnabled('odp')) {
        return next()
    }

    router.push('/')
}

export function mpsGuard({ next, router }) {
    if (store.getters.isProductEnabled('mps')) {
        return next()
    }

    router.push('/')
}

export function assistanceRequestGuard({ next, router }) {
    if (store.getters.isProductEnabled('assistance-request')) {
        return next()
    }

    return router.back()
}
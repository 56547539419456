<template>
    <div id="modal-monitor-add-edit" class="modal fade in" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">×</button>
                    <h4 class="modal-title" data-ng-bind-html="modal_monitor_form.title | trustAsHtml">{{ getTitle }}</h4>
                </div>
                <div class="modal-body" style="overflow: hidden;">
                    <form id="monitor-form" class="monitorFormIsAbout" method="post">
                        <component :is="modalComponent" />
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import ActionButton from '@/components/ActionButton'
    import {mapState, mapGetters, mapActions} from 'vuex';

    export default {
        props: {
            mobile: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                modal_component: 'PersonalDetails',
                modal_name: 'personal',
                save_close_btn_loading: false,
                save_continue_btn_loading: false
            }
        },
        components: {
            ActionButton
        },
        watch: {
            '$route.params' (to, from) {
                if (to.modal !== undefined) {
                    let component_name = this.getComponentName(to.modal.split('-'))

                    this.modalComponent = component_name

                    window.$('#modal-monitor-add-edit').modal('show')
                }

                from
            }
        },
        methods: {
            ...mapActions(['saveCustomer','getDashboardScores']),
            getComponentName(array) {
                let component_name = ''

                array.forEach((value) => {
                    component_name += value.charAt(0).toUpperCase() + value.slice(1)
                })

                return component_name
            },
            formatData(response) {
                let post_data = {
                    key: this.modal_name,
                    assets: []
                }

                switch (this.modal_name) {
                    case 'bank':
                    case 'address':
                    case 'cc': {
                        let data = {}

                        response.data.assets.forEach((value) => {
                            if (data[value.sd_id] === undefined) {
                                data[value.sd_id] = {}
                            }
                            for (let i in value) {
                                data[value.sd_id][i] = value[i]
                            }
                        })

                        Object.values(data).forEach(item => {
                            post_data.assets.push(item)
                        })

                        break;
                    }
                    default:
                        post_data.assets = response.data.assets
                }

                return post_data
            }
        },
        computed: {
            ...mapState(['customer', 'api']),
            ...mapGetters(['dashboardTranslations','getCustomer','commonTranslations']),
            modalComponent: {
                get() {
                    //For some reason the modalComponent function does not refresh without adding this
                    this.modal_component

                    return defineAsyncComponent(
                        () => import(`@/components/customer/modals/data_monitoring/${this.modal_component}`)
                    )
                },
                set (value) {
                    this.modal_component = value
                }
            },
            getTitle() {
                const dataEntries = this.customer.dashboard.data_monitoring_page.data_entries

                if (dataEntries[this.$route.params.modal] !== undefined) {
                    return this.customer.dashboard.data_monitoring_page.data_entries[this.$route.params.modal].title
                }

                return ''
            }
        },
        mounted() {
            window.$('#modal-monitor-add-edit').on('hide.bs.modal', ()=> {
                this.$router.push(this.mobile ? '/app/data-monitoring' : '/dashboard/data-monitoring');
            })
        },
        created() {
            setTimeout(()=>{
                if (this.$route.params.modal !== undefined) {
                    this.modalComponent = this.getComponentName(this.$route.params.modal.split('-'))

                    window.$('#modal-monitor-add-edit').modal('show')
                }
            }, 1000)
        }
    }
</script>

<style lang="scss">
    @import "toastr";

    .swal2-container {
        z-index: 20050 !important;
    }
</style>
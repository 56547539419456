<template>
    <svg :class="name" v-tippy :content="tooltip"></svg>
</template>

<script>
    import * as d3 from "d3";
    import {mapGetters} from 'vuex';

    export default {
        props: [
            'name',
            'radius',
            'value',
            'risk',
            'title',
            'subtitle',
            'text',
            'tooltip'
        ],
        data() {
            return {
                level_color: {},
                base_circle_radius: {
                    inner: parseInt(this.radius),
                    outer: parseInt(this.radius - 2)
                }
            }
        },
        computed: {
            ...mapGetters(['dashboardTranslations','getLevelColors'])
        },
        watch: {
            'value' () {
                this.levelColor(this.text)
                this.refresh()
            }
        },
        methods: {
            dial() {
                var svg = d3.select(`.${this.name}`)
                    .append("g")
                    .attr("transform", "translate(140,100)");

                var outerArc = d3.arc()
                    .innerRadius(this.base_circle_radius.inner)
                    .outerRadius(this.base_circle_radius.inner + 25)
                    .startAngle(2)
                    .endAngle(-2)
                    .cornerRadius(0);

                svg.append("path")
                    .attr("class", "arc")
                    .attr("d", outerArc)
                    .attr("fill", "rgb(236, 236, 236)");

                var innerArc = d3.arc()
                    .innerRadius(this.base_circle_radius.inner + 7)
                    .outerRadius(this.base_circle_radius.inner + 18)
                    .startAngle(this.valueToRadians(this.value))
                    .endAngle(-2)
                    .cornerRadius(0);

                svg.append("path")
                    .attr("class", "arc")
                    .attr("d", innerArc)
                    .attr("fill", this.level_color[this.risk]);

                svg.append("text")
                    .attr("class", "sub-text title")
                    .attr("text-anchor", "middle")
                    .attr("font-size", 18)
                    .style("fill", "black")
                    .text(this.title)
                    .attr("transform", "translate(0, -155)");

                svg.append("text")
                    .attr("class", "sub-text subtitle")
                    .attr("text-anchor", "middle")
                    .attr("font-size", 22)
                    .style("fill", "black")
                    .text(this.subtitle)
                    .attr("transform", "translate(0, -125)");

                svg.append("text")
                    .attr("class", "sub-text")
                    .attr("text-anchor", "middle")
                    .attr("font-size", 20)
                    .style("fill", "black")
                    .text(this.text)
                    .attr("transform", "translate(0, 0)");
            },
            valueToRadians(value, valueEnd = 100, angleStart = -115, angleEnd = 114, valueStart = 0) {
                return Math.PI / 180 * ((value - valueStart) * (angleEnd - angleStart) / (valueEnd - valueStart) + angleStart);
            },
            levelColor(risk) {
                this.level_color = {
                    low: this.getLevelColors.low,
                    medium: this.getLevelColors.medium,
                    high: this.getLevelColors.high
                }

                switch (risk) {
                    case 'low':
                        this.level_color.medium = '#FFFFFF'
                        this.level_color.high = '#FFFFFF'
                        break
                    case 'medium':
                        this.level_color.high = '#FFFFFF'
                        break
                }

                if (this.value === 0) {
                    this.level_color.low = '#FFFFFF'
                }

                return this.level_color[risk]
            },
            refresh() {
                d3.select(`.${this.name} g`).remove()
                this.dial()
            }
        },
        mounted() {
            this.levelColor(this.text)
            this.dial()
        }
    }
</script>
<style>
    text.sub-text.title {
        font-weight: bold;
    }
    
    text.sub-text.subtitle {
        font-weight: 300 !important;
    }
</style>
import axios from 'axios';
import store from '@/store'
import {getCookie, setCookie} from '@/libs/helpers';

const instance = axios.create({
    baseURL: process.env[`VUE_APP_API_URL`],
    params: {
        brand: process.env[`VUE_APP_BRAND`]
    }
});

instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

instance.interceptors.request.use((config) => {
    let token = getCookie('auth_token');

    if (token) {
        if (!token.includes('Bearer')) {
            token = `Bearer ${token}`;
        }

        config.headers['Authorization'] = token;
    }

    store.dispatch('updateLastActivity', new Date()).catch(() => {});
    store.dispatch('setLastActivityAlertDisplay', false).catch(()=>{})

    return config;
})

instance.interceptors.response.use((response) => {
    const { headers } = response;

    if (Object.prototype.hasOwnProperty.call(headers, 'authorization')) {
        const value = headers['authorization'];

        if (value) {
            console.log('token refreshed!');
            setCookie({name: 'auth_token', value});
        }
    }

    return response;
}, (error) => {
    return Promise.reject(error);
});

export default instance;
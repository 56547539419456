<template>
    <div :key="item.id" v-for="(item, index) in items" class="accordion-item" :class="{'is-active': item.active}">
        <dt class="accordion-item-title">
            <button @click="toggle(index)" class="accordion-item-trigger">
                <span :class="`fa ${item.active ? 'fa-minus-circle' : 'fa-plus-circle'}`"></span>
                <h4 class="accordion-item-title-text">{{item.title}}</h4>
                <span class="accordion-item-trigger-icon"></span>
            </button>
        </dt>
        <transition
                name="accordion-item"
                @enter="startTransition"
                @after-enter="endTransition"
                @before-leave="startTransition"
                @after-leave="endTransition">
            <dd v-if="item.active" class="accordion-item-details">
                <div v-html="item.text" class="accordion-item-details-inner"></div>
            </dd>
        </transition>
    </div>
</template>

<script>
    export default {
        props: ['content'],
        data() {
            return {
                items: this.content
            }
        },
        methods: {
            toggle(id) {
                this.items.forEach((item) => {
                    if (this.items[id].id !== item.id) {
                        item.active = false;
                    }
                });

                this.items[id].active = !this.items[id].active ? true : false;
            },

            startTransition(el) {
                el.style.height = el.scrollHeight + 'px'
            },

            endTransition(el) {
                el.style.height = ''
            }
        }
    }
</script>

<style scoped lang="scss">
    $browser-context: 16;
    $blue: #1779ba;
    $gray: #cacaca;
    $red: #cc4b37;
    $white: #ffffff;

    @function em($pixels, $context: $browser-context) {
        @return #{$pixels/$context}em;
    }

    .accordion-item-trigger .fa {
        font-size: 20px;
        position: absolute;
        top: 22px;
        left: 15px;
    }

    h4.accordion-item-title-text {
        padding-left: 40px;
        line-height: 28px;
    }

    .container {
        margin-bottom: em(24);
    }

    .btn-action-delete {
        color: $red;
        cursor: pointer;
    }

    .accordion {
        padding: 0;

        div:not(:last-child) {
            border-bottom: 1px solid rgba(10, 10, 10, 0.1);
        }

        div:last-child .accordion-item-details {
            border-radius: 5px;
        }

        dd {
            margin-left: 0;
        }
    }

    .accordion-item-trigger,
    .accordion-item-details-inner {
        padding: 0.75rem 1.25rem;
    }

    .accordion-item-title {
        position: relative;

        h4 {
            font-size: 20px;
            font-weight: 300;
            margin-bottom: 0;
            padding-right: 1.25rem;
        }
    }

    .accordion-item-details-inner p {
        line-height: 30px !important;
        padding-left: 20px;
    }

    .accordion-item-details-inner ul {
        padding-top: 10px;
        font-size: 15px !important;
        list-style-type: none;
    }

    .accordion-item-details-inner ol {
        padding-top: 10px;
        font-size: 15px !important;
    }

    .accordion-item-details-inner ol li {
        line-height: 30px;
        padding-left: 10px;
    }

    .accordion-item-details-inner ul li {
        position: relative;
        line-height: 30px;
        padding-left: 10px;
    }
    .accordion-item-details-inner ul li i {
        position: absolute;
        left: -15px;
        top: 8px;
    }

    .accordion-item-trigger {
        width: 100%;
        text-align: left;
        background-color: transparent;
        border: none;
    }

    .accordion-item-trigger-icon {
        $size: 8px;
        display: block;
        position: absolute;
        top: 0; right: 1.25rem; bottom: 0;
        margin: auto;
        width: $size;
        height: $size;
        border-right: 2px solid #363636;
        border-bottom: 2px solid #363636;
        transform: translateY(-$size / 4) rotate(45deg);
        transition: transform 0.2s ease;

    .is-active & {
        transform: translateY($size / 4) rotate(225deg);
    }
    }

    .accordion-item-details {
        overflow: hidden;
        background-color: whitesmoke;
    }

    .accordion-item-enter-active,
    .accordion-item-leave-active {
        will-change: height;
        transition: height 0.2s ease;
    }

    .accordion-item-enter-from,
    .accordion-item-leave-to {
        height: 0 !important;
    }
</style>
/**
 * Factory method to chain multiple middleware on routes for processing.
 *
 * @param context
 * @param middleware
 * @param index
 * @returns {(function(...[*]): void)|*}
 */
export default function nextFactory(context, middleware, index) {
    const thisMiddleware = middleware[index]

    if (!thisMiddleware) {
        return context.next
    }

    return (...parameters) => {
        context.next(...parameters)
        const nextMiddleware = nextFactory(context, middleware, index+1)

        thisMiddleware({
            ...context,
            next: nextMiddleware
        })
    };
}
import Toaster from '@meforma/vue-toaster';
import ToasterMixin from '@/components/Toaster'

Toaster.mixins = [ToasterMixin]

Toaster.config = {
    position: 'top',
    queue: true,
    maxToasts: 1
}

export default Toaster
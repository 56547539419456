<template>
    <svg :class="name" v-tippy="{placement: 'top', offset: [0,100]}" :content="tooltip"></svg>
</template>

<script>
    import * as d3 from "d3";
    import {mapGetters} from 'vuex';

    export default {
        props: [
            'radius',
            'value',
            'percent',
            'title',
            'text',
            'tooltip'
        ],
        data() {
            return {
                name: 'monitoring',
                base_circle_radius: {
                    inner: parseInt(this.radius),
                    outer: (parseInt(this.radius) - 2)
                }
            }
        },
        computed: {
            ...mapGetters(['dashboardTranslations', 'getBaseColour']),
            monitoringDialTxtItem() {
                return (this.value < 2) ? 'monitoring-dial-txt-item' : 'monitoring-dial-txt-items'
            },
            dataEmptyTxt() {
                let description = this.dashboardTranslations['dial-monitor-data-txt'].split('<br>')

                return (this.value === 0) ? description : false
            }
        },
        watch: {
            'value'() {
                this.refresh()
            },
            'percent'() {
                this.refresh()
            }
        },
        methods: {
            dial() {
                var svg = d3.select(`.${this.name}`)
                    .append("g")
                    .attr("transform", "translate(150,0)");

                var innerArc = d3.arc()
                    .innerRadius(this.base_circle_radius.inner)
                    .outerRadius(this.base_circle_radius.outer)
                    .startAngle(0)
                    .endAngle(Math.PI)
                    .cornerRadius(0);

                svg.append("path")
                    .attr("class", "arc")
                    .attr("d", innerArc)
                    .attr("fill", "rgb(155, 155, 155");

                var outerArc = d3.arc()
                    .innerRadius(this.base_circle_radius.inner + 5)
                    .outerRadius(this.base_circle_radius.outer - 5)
                    .startAngle(this.valueToRadians(Math.floor(this.percent), 100, 180, 0))
                    .endAngle(Math.PI)
                    .cornerRadius(0);

                svg.append("path")
                    .attr("class", "arc")
                    .attr("d", outerArc)
                    .attr("fill", this.getBaseColour);

                var innerCircle = d3.arc()
                    .innerRadius(this.base_circle_radius.inner - 15)
                    .outerRadius(0)
                    .startAngle(0)
                    .endAngle(360)
                    .cornerRadius(0);

                svg.append("path")
                    .attr("class", "arc")
                    .attr("d", innerCircle)
                    .attr("fill", "white")
                    .style("stroke", "rgb(236, 236, 236)");

                svg.append("text")
                    .attr("text-anchor", "middle")
                    .attr("font-size", 13)
                    .attr("font-weight", `${this.dataEmptyTxt !== false ? 'bold' : ''}`)
                    .style("fill", "black")
                    .text(this.dashboardTranslations['monitoring-dial-txt-monitoring'])
                    .attr("transform", "translate(0, -45)");

                if (this.dataEmptyTxt !== false) {
                    let count = 0

                    this.dataEmptyTxt.forEach(value => {
                        svg.append("text")
                            .attr("class", "data-empty")
                            .attr("text-anchor", "middle")
                            .attr("font-size", 13)
                            .style("fill", "black")
                            .text(value)
                            .attr("transform", `translate(0, ${20 * count})`)

                            count += 1
                    });
                } else {
                    svg.append("text")
                        .attr("text-anchor", "middle")
                        .attr("font-size", 55)
                        .style("fill", "black")
                        .text(this.value)
                        .attr("transform", "translate(0, 15)");

                    svg.append("text")
                        .attr("text-anchor", "middle")
                        .attr("font-size", 13)
                        .style("fill", "black")
                        .text(this.dashboardTranslations[this.monitoringDialTxtItem].toUpperCase())
                        .attr("transform", "translate(0, 45)");
                }
            },
            valueToRadians(value, valueEnd = 100, angleStart = -115, angleEnd = 114, valueStart = 0) {
                return Math.PI / 180 * ((value - valueStart) * (angleEnd - angleStart) / (valueEnd - valueStart) + angleStart);
            },
            refresh() {
                d3.select(`.${this.name} g`).remove()
                this.dial()
            }
        },
        mounted() {
            this.dial()
        }
    }
</script>

<style lang="css">
    .circle {
        overflow: hidden;
    }
    
    .data-empty:hover {
        cursor: pointer;
    }
</style>
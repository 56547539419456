<template>
    <a :href="link">
        <img
            ref="logo"
            :src="`${api.assets_url}/${getLogo}`"
            class="logo-default"
            :class="`logoHeight_${logo_height}`"
            alt="Europ Assistance" />
    </a>
</template>

<script>
    import { mapState, mapGetters } from "vuex"

    export default {
        props: {
            logo_height: {
                type: Number,
                default: 85
            }
        },
        data() {
            return {
                logo_width : 0,
            }
        },
        computed: {
            ...mapState(['api']),
            ...mapGetters(['getLogo']),
            link() {
                if (this.$route.fullPath !== '/') {
                    return '/'
                }

                return '#'
            }
        }
    }
</script>

<style scoped>
    img.logoHeight_85 {
        height: 85px !important;
        transition: .5s ease-in-out;
    }

    img.logoHeight_75 {
        height: 75px !important;
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
    }

    img.logoHeight_55 {
        height: 55px !important;
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
    }
</style>
<template>
    <svg :class="name" v-tippy="{placement: 'top', offset: [0,100]}" :content="tooltip"></svg>
</template>

<script>
    import * as d3 from "d3";
    import eventBus from '@/libs/eventBus'
    import {mapState, mapGetters} from 'vuex';

    export default {
        props: [
            'radius',
            'value',
            'assetCount',
            'title',
            'text',
            'tooltip'
        ],
        data() {
            return {
                name: 'risk_level',
                level_color: {},
                base_circle_radius: {
                    inner: parseInt(this.radius),
                    outer: parseInt(this.radius - 2)
                }
            }
        },
        computed: {
            ...mapState(['scores', 'customer']),
            ...mapGetters(['dashboardTranslations','getLevelColors']),
            dataEmptyTxt() {
                let description = this.dashboardTranslations['dial-monitor-data-txt'].split('<br>')

                return (this.value === 0) ? description : false
            }
        },
        watch: {
            'value' () {
                this.refresh()
            },
            'assetCount' () {
                this.refresh()
            },
        },
        methods: {
            dial() {
                var svg = d3.select(`.${this.name}`)
                    .append("g")
                    .attr("transform", "translate(150,0)")

                var innerArc = d3.arc()
                    .innerRadius(this.base_circle_radius.inner)
                    .outerRadius(this.base_circle_radius.outer)
                    .startAngle(0)
                    .endAngle(-Math.PI)
                    .cornerRadius(0)

                svg.append("path")
                    .attr("class", "arc")
                    .attr("d", innerArc)
                    .attr("fill", "rgb(155, 155, 155")

                var outerArc = d3.arc()
                    .innerRadius(this.base_circle_radius.inner + 5)
                    .outerRadius(this.base_circle_radius.outer - 5)
                    .startAngle(-this.valueToRadians(this.value, 100, 180, 0))
                    .endAngle(-Math.PI)
                    .cornerRadius(0)

                svg.append("path")
                    .attr("class", "outerArc")
                    .attr("d", outerArc)
                    .attr("fill", this.levelColor(this.scores.overall.risk))

                var innerCircle = d3.arc()
                    .innerRadius(this.base_circle_radius.inner - 15)
                    .outerRadius(0)
                    .startAngle(0)
                    .endAngle(360)
                    .cornerRadius(0)

                svg.append("path")
                    .attr("class", "arc")
                    .attr("d", innerCircle)
                    .attr("fill", "white")
                    .style("stroke", "rgb(236, 236, 236)")

                svg.append("text")
                    .attr("text-anchor", "middle")
                    .attr("font-size", 13)
                    .attr("font-weight", `${(this.dataEmptyTxt !== false && this.assetCount === 0) ? 'bold' : ''}`)
                    .style("fill", "black")
                    .text(this.dashboardTranslations['risk-level-dial-txt-risk-level'])
                    .attr("transform", "translate(0, -45)")

                if (this.dataEmptyTxt !== false && this.assetCount === 0) {
                    let count = 0

                    this.dataEmptyTxt.forEach(value => {
                        svg.append("text")
                            .attr("class", "data-empty")
                            .attr("text-anchor", "middle")
                            .attr("font-size", 13)
                            .style("fill", "black")
                            .text(value)
                            .attr("transform", `translate(0, ${20 * count})`)

                            count += 1
                    });
                } else {
                    svg.append("circle")
                        .attr("r", 6)
                        .attr("transform", "translate(0, 15)")
                        .style("fill", this.level_color.low)
                        .style("stroke", "#CCCCCC")

                    svg.append("circle")
                        .attr("r", 6)
                        .attr("transform", "translate(0, -5)")
                        .style("fill", this.level_color.medium)
                        .style("stroke", "#CCCCCC")

                    svg.append("circle")
                        .attr("r", 6)
                        .attr("transform", "translate(0, -25)")
                        .style("fill", this.level_color.high)
                        .style("stroke", "#CCCCCC")

                    svg.append("text")
                        .attr("text-anchor", "middle")
                        .attr("font-size", 15)
                        .attr("font-weight", 600)
                        .style("fill", this.levelColor(this.scores.overall.risk))
                        .text(this.dashboardTranslations[`risk-level-dial-txt-${this.scores.overall.risk}`].toUpperCase())
                        .attr("transform", "translate(0, 45)")
                }
            },
            valueToRadians(value, valueEnd = 100, angleStart = -115, angleEnd = 114, valueStart = 0) {
                return Math.PI / 180 * ((value - valueStart) * (angleEnd - angleStart) / (valueEnd - valueStart) + angleStart);
            },
            levelColor() {
                this.level_color = {
                    low: this.getLevelColors.low,
                    medium: this.getLevelColors.medium,
                    high: this.getLevelColors.high
                }

                switch (this.scores.overall.risk) {
                    case 'low':
                        this.level_color.medium = '#FFFFFF'
                        this.level_color.high = '#FFFFFF'
                        break
                    case 'medium':
                        this.level_color.low = '#ffc000'
                        this.level_color.high = '#FFFFFF'
                        break
                    case 'high':
                        this.level_color.low = '#E2020A'
                        this.level_color.medium = '#E2020A'
                        break
                }

                if (this.customer.loading) {
                    this.level_color.low = '#FFFFFF'
                }

                return this.level_color[this.scores.overall.risk]
            },
            refresh() {
                d3.select(`.${this.name} g`).remove()
                this.dial()
            }
        },
        mounted() {
            eventBus.$on('reloadDial', () => {
                this.dial()
            })

            this.levelColor()
            this.dial()
        }
    }
</script>

<style lang="css">
    .circle {
        overflow: hidden;
    }

    ~ .data-empty:hover {
        cursor: pointer;
        text-decoration: underline;
    }
</style>
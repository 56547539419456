<template>
    <vue-ladda
        class="btn btn-theme uppercase"
        :class="theme"
        style="margin-top: 20px;"
        :loading="loading"
        :data-style="data_style"
        :disabled="disabled"
        >
        <slot></slot>
    </vue-ladda>
</template>

<script>
    import VueLadda from 'vue3-ladda'
    import 'vue3-ladda/dist/vue-ladda.css'

    export default {
        props: {
            loading: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            data_style: {
                type: String,
                default: 'contract'
            },
            theme: {
                type: String,
                default: 'primary-pink'
            }
        },
        components: {
            VueLadda
        }
    }
</script>

<template>
    <table id="datatable-results" class="table table-striped table-bordered" cellspacing="0" width="100%">
        <thead>
            <tr>
                <th class="no-sort" v-tippy :content="dashboardTranslations['identity-fraud-page-th-id-tooltip']">
                    ID
                </th>
                <th v-tippy :content="dashboardTranslations['identity-fraud-page-th-found-tooltip']">
                    {{ dashboardTranslations['identity-fraud-page-th-found'] }}
                </th>
                <th v-tippy :content="dashboardTranslations['identity-fraud-page-th-match-tooltip']">
                    {{ dashboardTranslations['identity-fraud-page-th-match'] }}
                </th>
                <th v-tippy :content="dashboardTranslations['identity-fraud-page-th-date-tooltip']">
                    {{ dashboardTranslations['identity-fraud-page-th-date'] }}
                </th>
                <th v-tippy :content="dashboardTranslations['identity-fraud-page-th-where-tooltip']">
                    {{ dashboardTranslations['identity-fraud-page-th-where'] }}
                </th>
                <th v-tippy :content="dashboardTranslations['identity-fraud-page-th-online-address-tooltip']">
                    {{ dashboardTranslations['identity-fraud-page-th-online-address'] }}
                </th>
                <th v-tippy :content="dashboardTranslations['identity-fraud-page-th-risk-tooltip']">
                    {{ dashboardTranslations['identity-fraud-page-th-risk'] }}
                </th>
                <th v-tippy :content="dashboardTranslations['identity-fraud-page-th-help-tooltip']" style="width: 80px;">
                    {{ dashboardTranslations['identity-fraud-page-th-help'] }}
                </th>
                <th v-tippy :content="dashboardTranslations['identity-fraud-page-th-actions-tooltip']" style="width: 100px;">
                    {{ dashboardTranslations['identity-fraud-page-th-actions'] }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td colspan="9" align="center">Loading</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
    import $ from 'jquery'
    import "datatables.net-dt/js/dataTables.dataTables"
    import "datatables.net-dt/css/jquery.dataTables.min.css"
    import axios from './../../../libs/axios'
    import {mapState, mapActions, mapGetters} from 'vuex';

    export default {
        props: {
            mobile: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                table: ''
            }
        },
        computed: {
            ...mapState(['api', 'customer', 'brand']),
            ...mapGetters(['dashboardTranslations','commonTranslations']),
            identityRiskPage() {
                return this.customer.dashboard.identity_risk_page;
            }
        },
        methods: {
            ...mapActions(['getDashboardScores']),
            createDataTable(hits) {
                const columns = [
                        { data: 'hit_id' },
                        { data: "found" },
                        { data: "match" },
                        { data: "date" },
                        { data: "where" },
                        { data: "online_address" },
                        { data: "risk" },
                        { data: 'help' },
                        { data: "alert_percent" },
                        { data: 'help_opened' },
                    ],
                    columnDefs = []

                for (var i in columns) {
                    switch (columns[i].data) {
                        case 'help_opened':
                            columnDefs.push({
                                targets     : [parseInt(i)],
                                searchable  : false,
                                orderable 	: false,
                                bVisible    : false,
                                render      : (data) => {
                                    return data;
                                }
                            })
                            break

                        case 'online_address': {
                            columnDefs.push({
                                targets: [parseInt(i)],
                                visible: true,
                                searchable: true,
                                render: (data, type, row) => {
                                    let data_link = data;

                                    if (row.where == 'publicweb') {
                                        let info_found = this.dashboardTranslations['identity-fraud-page-personal-information-found']

                                        return `${info_found !== undefined ? info_found : ''} <a href="${data}" target="_blank" style="text-decoration: underline;">${data}</a>`;
                                    }

                                    type

                                    return data_link;
                                }
                            })
                            break
                        }

                        case 'hit_id':
                            columnDefs.push({
                                targets     : [parseInt(i)],
                                searchable  : true,
                                orderable 	: false,
                                visible     : true,
                                render 		: (data) => {
                                    return data
                                }
                            })
                            break

                        case 'help':
                            columnDefs.push({
                                targets: [parseInt(i)],
                                visible: true,
                                searchable: false,
                                orderable 	: false,
                                render: (data, type, row, meta) => {
                                    let where = (row.where !== 'publicweb') ? 'darkweb' : 'publicweb'

                                    return `<svg width="50" assist-row-id="${meta.row}" assist-where="${where}" assist-found="${row.found}" class="assist-info"><use xlink:href="#question-mark" /></svg>`;
                                }
                            })
                            break

                        case 'where':
                            columnDefs.push({
                                targets     : [parseInt(i)],
                                visible     : true,
                                searchable 	: true,
                                render 		: (data) => {
                                    var $data = 'Public Web';

                                    if (data !== 'publicweb') {
                                        $data = this.dashboardTranslations['identity-page-dark-web-text']
                                    }

                                    return $data
                                }
                            })
                            break

                        case 'found':
                            columnDefs.push({
                                targets     : [parseInt(i)],
                                visible     : true,
                                searchable 	: true,
                                render 		: (data) => {
                                    var $data = data;

                                    switch (data) {
                                        case 'email':
                                            $data = this.dashboardTranslations['data-monitoring-page-circle-name-email']
                                            break
                                        case 'credit_card':
                                            $data = this.dashboardTranslations['identity-risk-page-found-credit-card']
                                            break
                                        case 'address':
                                            $data = this.dashboardTranslations['data-monitoring-page-circle-name-address']
                                            break
                                        case 'dob':
                                            $data = this.dashboardTranslations['data-entry-form-dob']
                                            break
                                        case 'phone':
                                            $data = this.dashboardTranslations['data-monitoring-page-circle-name-landline']
                                            break
                                        case 'bank_account bank_code':
                                            $data = this.dashboardTranslations['data-monitoring-page-circle-name-bank']
                                            break
                                        case 'mobile':
                                            $data = this.dashboardTranslations['data-monitoring-page-circle-name-mobile']
                                            break
                                        case 'driving_license':
                                            $data = this.dashboardTranslations['data-monitoring-page-circle-name-driver-license']
                                            break
                                        case 'passport':
                                            $data = this.dashboardTranslations['data-monitoring-page-circle-name-passport']
                                            break
                                        case 'ssn':
                                        case 'id_card':
                                            $data = this.dashboardTranslations['identity-risk-page-found-ni']
                                            break
                                        case 'ebay':
                                            $data = this.dashboardTranslations['data-monitoring-page-circle-name-ebay']
                                            break
                                        case 'paypal':
                                            $data = this.dashboardTranslations['data-monitoring-page-circle-name-paypal']
                                            break
                                        case 'iban bic':
                                            $data = this.dashboardTranslations['data-monitoring-page-circle-name-email']
                                            break
                                    }

                                    return $data
                                }
                            })
                            break

                        case 'risk':
                            columnDefs.push({
                                targets     : [parseInt(i)],
                                visible     : true,
                                searchable 	: true,
                                render 		: (data) => {
                                    let translated = this.dashboardTranslations[`risk-level-dial-txt-${data}`]

                                    return translated.charAt(0).toUpperCase() + translated.slice(1).toLowerCase()
                                }
                            })
                            break

                        case 'alert_percent':
                            columnDefs.push({
                                targets     : [parseInt(i)],
                                visible     : true,
                                sortable    : false,
                                searchable  : false,
                                render      : (data, type, row) => {
                                    type

                                    return `<svg 
                                                width="50" 
                                                data-id="${row.hit_id}"
                                                data-type="_ignore"
                                                title="${this.dashboardTranslations['identity-fraud-page-ignore-action']}">
                                                <use xlink:href="#eye" />
                                                </svg>`;
                                }
                            })
                            break;
                    }
                }

                this.table = $('#datatable-results').DataTable({
                    searching: false,
                    data: hits,
                    columns: columns,
                    columnDefs: columnDefs,
                    responsive 	: true,
                    destroy     : true,
                    order       : [[0, "desc"]],
                    language    : {
                        search 		: this.dashboardTranslations['identity-fraud-page-table-search'],
                        info 		: this.dashboardTranslations['identity-fraud-page-table-show-start-end-total'],
                        lengthMenu 	: this.dashboardTranslations['identity-fraud-page-table-show'] + " _MENU_ " + this.dashboardTranslations['identity-fraud-page-table-entries'],
                        infoEmpty   : this.dashboardTranslations['identity-fraud-page-table-show-start-end-total-0'],
                        emptyTable  : this.dashboardTranslations['identity-fraud-page-table-empty'],
                        infoFiltered: this.dashboardTranslations['identity-fraud-page-table-filtered'] ? this.dashboardTranslations['identity-fraud-page-table-filtered'] : "(filtered from _MAX_ total entries)",
                        paginate 	: {
                            next 		: this.dashboardTranslations['identity-fraud-page-table-next'],
                            previous	: this.dashboardTranslations['identity-fraud-page-table-previous']
                        }
                    },
                    createdRow	: function(row, data) {
                        var risk_row = $('td', row).eq(6),
                            help_row = $('td', row).eq(7),
                            ignore_row = $('td', row).eq(8);

                        risk_row.addClass('text-center');
                        risk_row.addClass('danger-' + data.risk);

                        if (data.help_opened == 0) {
                            $('td', row).css('font-weight', 'bold');
                        }

                        help_row.addClass('help');
                        ignore_row.addClass('ignore');
                    }
                })

                $.fn.dataTable.ext.errMode = 'none';

                const $this = this

                $('tbody').on('click', '[data-type=_ignore]', function(){
                    $this.$swal.fire({
                        title: $this.dashboardTranslations['identity-fraud-page-ignore-confirm-title'],
                        text: $this.dashboardTranslations['identity-fraud-page-ignore-confirm-text'],
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: $this.dashboardTranslations['identity-fraud-page-ignore-action'],
                        cancelButtonText: $this.dashboardTranslations['identity-fraud-page-cancel-action'],
                    }).then((result)=>{
                        if (result.isConfirmed) {
                            $this.$swal.fire({
                                title: $this.commonTranslations['data-processing'],
                                allowOutsideClick: false,
                                didOpen: () => {
                                    $this.$swal.showLoading()

                                    let pathPrefix = '',
                                        payload = {
                                            id: parseInt($(this).attr('data-id'))
                                        };

                                    // update path + payload if in mobile mode
                                    if ($this.mobile) {
                                        pathPrefix = 'mobile/';
                                        const {token, iv} = $this.mobile;
                                        payload = {...payload, token, iv};
                                    }

                                    axios.put(`${$this.api.url}/${pathPrefix}dashboard/identity-risk/ignore`, payload)
                                    .then(
                                        response => {
                                            if (response.status === 200) {
                                                $(this).parents('tr').remove();

                                                $this.$swal.closeModal()

                                                $this.getDashboardScores()
                                                $this.$emit('onUpdateResultsTable')
                                            }
                                        }
                                    )
                                }
                            })
                        }
                    })
                })

                $('tbody').on('click', 'svg.assist-info', function() {
                    const assist_where = $(this).attr('assist-where')
                    const assist_found = $(this).attr('assist-found')
                    const row_id = $(this).attr('assist-row-id')

                    var row = $this.table.row(row_id).data()

                    if (row.help_opened == 0) {
                        row.help_opened = 1

                        $(this).parents('tr').find('td').css('font-weight', 'normal')

                        let payload = {
                            id: parseInt(row.hit_id)
                        }

                        if ($this.mobile) {
                            const {token, iv} = $this.mobile
                            payload = {...payload, token, iv}
                        }

                        axios.put(`${$this.api.url}/${$this.mobile ? 'mobile/' : ''}dashboard/identity-risk/help-opened`, payload)
                        .then(
                            response => {
                                console.log(response)
                            }
                        ).catch(
                            error => {
                                console.log(error.response)
                            }
                        )
                    }

                    $this.$router.push(`${$this.mobile ? '/app' : '/dashboard'}/identity-risk/${assist_where}/${assist_found}`)
                })
            }
        },
        mounted() {
            this.createDataTable(this.customer.dashboard.identity_risk_page.hits)
        }
    }
</script>

<style lang="scss">
    table.dataTable.no-footer {
        border: none !important;
    }

    .dataTables_wrapper .dataTables_paginate {
        float: right;
        text-align: right;
        padding-top: 0 !important;
        border: 1px solid #ddd;
        margin-top: 10px;
        font-size: 15px;
    }

    .dataTables_wrapper .dataTables_paginate .paginate_button {
        padding: 0.3em 1em !important;
    }

    .dataTables_wrapper .dataTables_paginate .paginate_button.current,
    .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
        border: 1px solid $base-color;
        background: $base-color;
        color: #FFF !important;
    }

    table#datatable-results svg:hover {
        -webkit-filter: drop-shadow( 4px 6px 6px rgba(0, 0, 0, .5));
        filter: drop-shadow( 4px 6px 6px rgba(0, 0, 0, .5));

        fill: $base-color !important;
    }

    table.dataTable thead th {
        padding: 15px 18px !important;
    }

    table.dataTable tbody td {
        padding: 17px 10px !important;
    }

    .table-bordered > thead > tr > th {
        border: none !important;
    }

    .table-bordered > thead {
        background-color: #D9D9D9 !important;
    }

    table#datatable-results>tbody>tr>td {
        border: none !important;
    }

    .table-striped>tbody>tr:nth-of-type(even) {
        background-color: #F2F2F2 !important;
    }

    .table-striped>tbody>tr:nth-of-type(odd) {
        background-color: #FFFFFF !important;
    }
</style>
<template>
    <div class="form-group health-checker">
        <label class="control-label">{{ authTranslations['registration-health-checker-label'] }}</label>
        <p>{{ authTranslations['registration-health-checker-description'] }}</p>
        <ul>
            <li :key="key" v-for="(item, key) in auth.health_checker_items.list" :class="`${item.class} ${item.accepted ? 'accepted' : ''}`">
                <span>
                    <svg><use :href="`#${item.accepted ? 'accept' : 'cancel'}`"></use></svg>
                </span>
                {{ authTranslations[item.label] }}
            </li>
        </ul>
    </div>
</template>

<script>
    import {mapState, mapGetters} from 'vuex';

    export default {
        props: {
            password_text: String
        },
        data() {
            return {
                health_checker: {
                    list: [],
                    accepted: false
                }
            }
        },
        computed: {
            ...mapState(['auth']),
            ...mapGetters(['authTranslations'])
        },
        watch: {
            password_text(text) {
                let accepted = true

                this.health_checker.list.forEach((value)=>{
                    value.accepted = false

                    if (value.type === 'length') {
                        if (text.length >= 8) {
                            value.accepted = true
                        }
                    }

                    if (value.type === 'regex') {
                        let string = value.value,
                            match = text.match(string)

                        if (match) {
                            value.accepted = true
                        }
                    }

                    if (value.accepted === false) {
                        accepted = false
                    }
                })

                this.health_checker.accepted = accepted

                this.$store.dispatch('updateHealthChecker', this.health_checker)
            }
        },
        created() {
            this.health_checker.list = [
                {
                    class: 'character-length',
                    type: 'length',
                    value: 8,
                    label: 'registration-health-checker-character-length',
                    accepted: false
                },
                {
                    class: 'uppercase-letter',
                    type: 'regex',
                    value: '[A-Z]',
                    label: 'registration-health-checker-uppercase-letter',
                    accepted: false
                },
                {
                    class: 'lowercase-letter',
                    type: 'regex',
                    value: '[a-z]',
                    label: 'registration-health-checker-lowercase-letter',
                    accepted: false
                },
                {
                    class: 'numerical',
                    type: 'regex',
                    value: '[0-9]',
                    label: 'registration-health-checker-numerical',
                    accepted: false
                },
                {
                    class: 'symbol',
                    type: 'regex',
                    value: '[^0-9A-Za-z]',
                    label: 'registration-health-checker-symbols',
                    accepted: false
                }
            ]

            this.$store.dispatch('updateHealthChecker', this.health_checker)
        }
    }
</script>

<style scoped lang="scss">
    .form-group.health-checker {
        padding: 0 0 20px 20px;
    }

    .form-group.health-checker p {
        margin: 10px 0;
    }

    .form-group.health-checker ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    .form-group.health-checker ul li {
        position: relative;
        font-size: 16px;
        color: $danger-high;
        font-weight: 400;
        margin-bottom: 15px;
        padding-left: 35px;
    }

    .form-group.health-checker ul li svg {
        position: absolute;
        left: 0;
        top: 2px;
        fill: $danger-high;
        width: 25px;
        height: 25px;
    }

    .form-group.health-checker ul li.accepted svg,
    .form-group.health-checker ul li.accepted {
        color: #28a745;
        fill: #28a745;
    }
</style>
